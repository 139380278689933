.chat-container {
  width: 100%;
  height: 100%;
  background-color: red;
}

.chatbox {
  flex: 1;
  overflow-y: scroll;
  height: 0px;
}