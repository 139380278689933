body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: url('./background.png') !important;
  background-position: top center !important;
  background-size: 100% !important;
  background-repeat: no-repeat !important;
  background-color: #F6F6F6 !important;
}


html, body, #root {
  width: 100%;
  height: 100%;
}